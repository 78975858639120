var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.folder.publications.name")) + " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.folder.publications.titleLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "title", debounce: "500" },
                on: {
                  update: function($event) {
                    return _vm.$emit("updateFolderSettings", "name", $event)
                  }
                },
                model: {
                  value: _vm.folderData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.folderData, "name", $$v)
                  },
                  expression: "folderData.name"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.folder.publications.titleDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.folder.publications.permalinkLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                class: _vm.isInputInvalid ? "is-invalid" : "",
                attrs: { id: "permalink", debounce: "500" },
                on: { update: _vm.setSlug },
                model: {
                  value: _vm.folderData.slug,
                  callback: function($$v) {
                    _vm.$set(_vm.folderData, "slug", $$v)
                  },
                  expression: "folderData.slug"
                }
              }),
              _vm.isInputInvalid
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.folderData.slug.disallowIndex
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.disallowIndexForSlug")) +
                              " "
                          )
                        ])
                      : !_vm.$v.folderData.slug.duplicateSlug
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.slugDuplicate")) + " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.folderData.slug.required
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.notEmpty")) + " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.folder.publications.permalinkDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-visible-on-overview",
                    name: "isVisibleOnOverview",
                    size: "lg",
                    value: true,
                    "unchecked-value": false
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("updateFolderSettings", "listed", $event)
                    }
                  },
                  model: {
                    value: _vm.folderData.listed,
                    callback: function($$v) {
                      _vm.$set(_vm.folderData, "listed", $$v)
                    },
                    expression: "folderData.listed"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.folder.publications.listedOnOverviewPage"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.folder.publications.listedOnOverviewPageDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("item-language", {
        attrs: { "language-id": _vm.folderData.language_id },
        on: {
          "language-changed": function($event) {
            return _vm.$emit("updateFolderSettings", "language", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }