<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.folder.publications.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.publications.titleLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="title"
          v-model="folderData.name"
          debounce="500"
          @update="$emit('updateFolderSettings', 'name', $event)"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.folder.publications.titleDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.publications.permalinkLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="permalink"
          :class="(isInputInvalid) ? 'is-invalid' : ''"
          v-model="folderData.slug"
          debounce="500"
          @update="setSlug"
        ></b-form-input>
        <div
          v-if="isInputInvalid"
          class="invalid-feedback"
        >
          <span v-if="!$v.folderData.slug.disallowIndex">
            {{ $t('settings.disallowIndexForSlug') }}
          </span>
          <span v-else-if="!$v.folderData.slug.duplicateSlug">
            {{ $t('settings.slugDuplicate') }}
          </span>
          <span v-if="!$v.folderData.slug.required">
            {{ $t('settings.notEmpty') }}
          </span>
        </div>
        <b-form-text
          v-html="$t('settings.folder.publications.permalinkDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="is-visible-on-overview"
          name="isVisibleOnOverview"
          size="lg"
          v-model="folderData.listed"
          :value="true"
          :unchecked-value="false"
          @change="$emit('updateFolderSettings', 'listed', $event)"
        >
          {{ $t('settings.folder.publications.listedOnOverviewPage') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.folder.publications.listedOnOverviewPageDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <item-language
      :language-id="folderData.language_id"
      @language-changed="$emit('updateFolderSettings', 'language', $event)"
    />
  </div>
</template>

<script>
import SetButtonDisabledInCache from '@/graphQlQueries/mutations/setButtonDisabledInCache';
import slugify from '@sindresorhus/slugify';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const duplicateSiblingsSlugValidator = (slugsOfAllSiblings) => (value) => {
  const slugifiedValue = slugify(value.trim());
  return !slugsOfAllSiblings.includes(slugifiedValue);
};

const disallowIndex = (value) => slugify(value.trim()) !== 'index';

const checkForSlugify = (value) => {
  const slugifiedValue = slugify(value.trim());
  return slugifiedValue === value.trim();
};

export default {
  name: 'FolderSettingsPublications',
  mixins: [validationMixin],
  components: {
    ItemLanguage: () => import('@/components/settings/ItemLanguage.vue'),
  },
  props: ['foldersData', 'siblingSlugs'],
  validations() {
    return {
      folderData: {
        slug: {
          required,
          checkForSlugify,
          disallowIndex,
          duplicateSlug: duplicateSiblingsSlugValidator(this.siblingSlugs),
        },
      },
    };
  },
  data() {
    return {
      folderData: null,
    };
  },
  created() {
    // initially set the disable state of the button on false
    this.$apollo.mutate({
      mutation: SetButtonDisabledInCache,
      variables: {
        isButtonDisabled: false,
      },
    });
    this.folderData = { ...this.foldersData };
  },
  methods: {
    async setSlug(value) {
      this.$v.folderData.slug.$touch();
      if (!this.$v.folderData.slug.checkForSlugify) {
        this.folderData.slug = slugify(value.trim());
      }
      if (this.isInputInvalid) {
        // disable the save button
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: true,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: false,
          },
        });
        this.$emit('updateFolderSettings', 'slug', slugify(value.trim()));
      }
    },
  },
  computed: {
    isInputInvalid() {
      if (this.$v.folderData.slug.$anyDirty) {
        if (
          !this.$v.folderData.slug.duplicateSlug
          || !this.$v.folderData.slug.disallowIndex
          || !this.$v.folderData.slug.checkForSlugify
          || !this.$v.folderData.slug.required
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
//
</script>
